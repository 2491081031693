<template>
  <div
    class="webkit-slideshow-container"
    :class="index == 1 ? 'webkit-spacing-first' : 'webkit-spacing-normal'"
  >
    <div class="webkit-slideshow" v-if="slides.length > 1">
      <agile :throttleDelay="0" :autoplay="true" :autoplaySpeed="4000">
        <div class="slide grid" v-for="slide in slides">
          <div
            class="webkit-slide-image col-start-1 col-end-1 row-start-1 row-end-1"
            v-bind:style="{ backgroundImage: 'url(\'' + slide.image + '\')' }"
          >
            <img class="img-hidden" :src="slide.image" :alt="slide.alt" />
          </div>
          <div :class="slide.text ? 'webkit-slide-bg' : ''"></div>
          <div class="webkit-slider-innner-grid-container">
            <div class="grid grid-cols-24">
              <div class="webkit-slide-text-container">
                <div class="webkit-slide-heading">{{ slide.heading }}</div>
                <div class="webkit-slide-text">{{ slide.text }}</div>
                <div class="webkit-slide-button-container" v-if="slide.link">
                  <a class="webkit-slide-button" :href="slide.link">
                    <div class="webkit-btn__big" v-if="slide.linkText">
                      {{ slide.linkText }}
                    </div>
                    <i class="webkit-btn__small webkit-arrow-right" v-else></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="prevButton"><i class="fal fa-angle-left"></i></template>
        <template slot="nextButton"
          ><i class="fal fa-angle-right"></i
        ></template>
      </agile>
    </div>

    <div class="webkit-slideshow" v-else-if="slides.length > 0">
      <div class="slide grid">
        <div
          class="webkit-slide-image col-start-1 col-end-1 row-start-1 row-end-1"
          v-bind:style="{ backgroundImage: 'url(\'' + slides[0].image + '\')' }"
        >
          <img class="img-hidden" :src="slides[0].image" :alt="slides[0].alt" />
        </div>
        <div :class="slides[0].text ? 'webkit-slide-bg' : ''"></div>
        <div class="webkit-slider-innner-grid-container">
          <div class="grid grid-cols-24">
            <div class="webkit-slide-text-container">
              <div class="webkit-slide-heading">{{ slides[0].heading }}</div>
              <div class="webkit-slide-text">{{ slides[0].text }}</div>
              <div class="webkit-slide-button-container" v-if="slides[0].link">
                <a class="webkit-slide-button" :href="slides[0].link">{{
                  slides[0].linkText
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import Vue, { VNode } from 'vue';

export default Vue.extend({
  name: "slide-show",
  props: ["slides", "index"],
  components: {
    agile: VueAgile,
  },
  data() {
    //read slider config from config file
    return {
      autoplay: true,
    };
  },
});
</script>
