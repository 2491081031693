var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "webkit-slideshow-container",
      class: _vm.index == 1 ? "webkit-spacing-first" : "webkit-spacing-normal"
    },
    [
      _vm.slides.length > 1
        ? _c(
            "div",
            { staticClass: "webkit-slideshow" },
            [
              _c(
                "agile",
                {
                  attrs: {
                    throttleDelay: 0,
                    autoplay: true,
                    autoplaySpeed: 4000
                  }
                },
                [
                  _vm._l(_vm.slides, function(slide) {
                    return _c("div", { staticClass: "slide grid" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "webkit-slide-image col-start-1 col-end-1 row-start-1 row-end-1",
                          style: {
                            backgroundImage: "url('" + slide.image + "')"
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "img-hidden",
                            attrs: { src: slide.image, alt: slide.alt }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { class: slide.text ? "webkit-slide-bg" : "" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "webkit-slider-innner-grid-container" },
                        [
                          _c("div", { staticClass: "grid grid-cols-24" }, [
                            _c(
                              "div",
                              { staticClass: "webkit-slide-text-container" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "webkit-slide-heading" },
                                  [_vm._v(_vm._s(slide.heading))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "webkit-slide-text" },
                                  [_vm._v(_vm._s(slide.text))]
                                ),
                                _vm._v(" "),
                                slide.link
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "webkit-slide-button-container"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "webkit-slide-button",
                                            attrs: { href: slide.link }
                                          },
                                          [
                                            slide.linkText
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "webkit-btn__big"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(slide.linkText) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _c("i", {
                                                  staticClass:
                                                    "webkit-btn__small webkit-arrow-right"
                                                })
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c("template", { slot: "prevButton" }, [
                    _c("i", { staticClass: "fal fa-angle-left" })
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "nextButton" }, [
                    _c("i", { staticClass: "fal fa-angle-right" })
                  ])
                ],
                2
              )
            ],
            1
          )
        : _vm.slides.length > 0
        ? _c("div", { staticClass: "webkit-slideshow" }, [
            _c("div", { staticClass: "slide grid" }, [
              _c(
                "div",
                {
                  staticClass:
                    "webkit-slide-image col-start-1 col-end-1 row-start-1 row-end-1",
                  style: {
                    backgroundImage: "url('" + _vm.slides[0].image + "')"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-hidden",
                    attrs: { src: _vm.slides[0].image, alt: _vm.slides[0].alt }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { class: _vm.slides[0].text ? "webkit-slide-bg" : "" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "webkit-slider-innner-grid-container" },
                [
                  _c("div", { staticClass: "grid grid-cols-24" }, [
                    _c("div", { staticClass: "webkit-slide-text-container" }, [
                      _c("div", { staticClass: "webkit-slide-heading" }, [
                        _vm._v(_vm._s(_vm.slides[0].heading))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "webkit-slide-text" }, [
                        _vm._v(_vm._s(_vm.slides[0].text))
                      ]),
                      _vm._v(" "),
                      _vm.slides[0].link
                        ? _c(
                            "div",
                            { staticClass: "webkit-slide-button-container" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "webkit-slide-button",
                                  attrs: { href: _vm.slides[0].link }
                                },
                                [_vm._v(_vm._s(_vm.slides[0].linkText))]
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }