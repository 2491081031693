<template>
  <div class="webkit-testimonial-outer-grid">
    <div class="webkit-testimonial-cell">
      <agile
        :throttleDelay="0"
        :autoplay="true"
        :autoplaySpeed="12000"
        v-if="slides.length > 1"
      >
        <a
          class="webkit-testimonial-container overflow-hidden relative"
          :href="slide.link"
          :target="slide.target"
          v-for="(slide, index) in slides"
          v-bind:key="slide.title + '-' + index"
        >
          <div class="webkit-testimonial-grid">
            <div class="webkit-testimonial-image-container">
              <div
                class="webkit-testimonial-image"
                v-bind:style="{
                  backgroundImage: 'url(\'' + slide.image + '\')',
                }"
              >
                <img class="img-hidden" :src="slide.image" :alt="slide.alt" />
                <div class="webkit-testimonial-image-aspect"></div>
              </div>
            </div>
            <div class="webkit-testimonial-title">{{ slide.title }}</div>
            <div class="webkit-testimonial-text">
              {{ slide.text }}
            </div>
          </div>
        </a>
        <template slot="prevButton"><i class="fal fa-angle-left"></i></template>
        <template slot="nextButton"
          ><i class="fal fa-angle-right"></i
        ></template>
      </agile>
      <slot v-else name="testimonial-single"></slot>
    </div>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import Vue, { VNode } from 'vue';

export default Vue.extend({
  name: "testimonial-slider",
  props: ["slides"],
  components: {
    agile: VueAgile,
  },
});
</script>
