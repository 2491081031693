const fonts = require('../../base/theme.fonts.config');

module.exports = {
  backgroundImageAspect: 'pt-10/24 xl:pt-11/24 l:pt-2/1 s:pt-4/3 xs:pt-1/1',
  boxBgColor: 'bg-color2',

  heading: {
    font: 'text-f1 m:text-f1m s:text-f1xs font-black text-color3',
  },
  text: {
    font: fonts.f5n + ' text-color1',
  },

  arrowColor: 'text-color2',

  autoplay: true,
  autoplaySpeed: 3500, //in ms
  fade: true,
  speed: 2000,
  timing: 'ease'
}