<template>
  <div class="webkit-akkordeon-entry">
    <div @click="show = !show" class="webkit-akkordeon-title">
      <div class="webkit-akkordeon-title-text">{{ heading }}</div>
      <div class="webkit-akkordeon-icon-container">
        <i
          v-bind:class="{
            'webkit-minus webkit-akkordeon-active': show,
            'webkit-plus': !show,
          }"
          aria-hidden="true"
          class="webkit-btn__small-2"
        ></i>
      </div>
    </div>
    <div
      class="webkit-akkordeon-text-container"
      :class="{ 'akkordeon-active': show }"
      ref="akkordeonContainer"
    >
      <div
        class="webkit-akkordeon-text webkit-redactor-content"
        ref="akkordeonText"
        v-html="text"
      ></div>
    </div>
  </div>
</template>

<script>
import Vue, { VNode } from 'vue';

export default Vue.extend({
  name: "Akkordeon",
  props: {
    heading: String,
    text: String,
  },

  data() {
    return {
      show: false,
    };
  },
  updated: function () {
    let height = this.$refs.akkordeonText.clientHeight;
    if (this.show) {
      this.$refs.akkordeonContainer.style.height = height + "px";
    } else {
      this.$refs.akkordeonContainer.style.height = 0;
    }
  },
});
</script>
