interface SliderConfigs {
  default: JQuerySlickOptions;
  [ key: string ]: JQuerySlickOptions;
}

export abstract class Slider {
  public static readonly SLIDER_CONFIGS: SliderConfigs = {
    default: {
      autoplay: false,
      infinite: true,
      arrows: true,
      dots: false,
      respondTo: "window",
    },
    "slider-doubleGrid": {
      infinite: true,
      autoplaySpeed: 12000,
      autoplay: true,
      arrows: true,
      prevArrow:
        '<div class="slick-prev-grid slick-arrow-grid"><i class="fal fa-angle-left"></i></div>',
      nextArrow:
        '<div class="slick-next-grid slick-arrow-grid"><i class="fal fa-angle-right"></i></div>',
      slidesToShow: 2,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
    "slider-grid": {
      infinite: true,
      autoplaySpeed: 3500,
      autoplay: true,
      arrows: true,
      prevArrow:
        '<div class="slick-prev-grid slick-arrow-grid"><i class="fal fa-angle-left"></i></div>',
      nextArrow:
        '<div class="slick-next-grid slick-arrow-grid"><i class="fal fa-angle-right"></i></div>',
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1201, // active at <= 1200px
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  };

  public static initSliders(): void {
    const sliders = $( ".slider" );
    sliders.each( ( i, el ) => {
      Slider.initSlider( $( el ) );
    } );
  }

  private static initSlider( el: JQuery ): void {
    const config = Slider.getConfig( el );
    el.slick( config );
  }

  public static getConfig( el: JQuery ): JQuerySlickOptions {
    const dataConfig = el.data( "slider" );
    if (
      typeof dataConfig === "string" &&
      dataConfig.length > 0 &&
      dataConfig in Slider.SLIDER_CONFIGS
    ) {
      console.log( "using slider config " + dataConfig );
      return Slider.SLIDER_CONFIGS[ dataConfig ];
    }
    console.log( "using slider config default" );
    return Slider.SLIDER_CONFIGS.default;
  }
}
