import { VDirective } from '@libTs/vue-base';
import { DirectiveBinding } from 'vue/types/options';

export const spacing: VDirective = [
  'spacing', {
    bind(el: HTMLElement, binding: DirectiveBinding) {
      $(function () {
        const classList = ['webkit-slideshow-container', 'webkit-slideshow-container'];
        const firstEl = el.firstChild as HTMLElement;
        const innerEl = firstEl.firstChild as HTMLElement;

        if (!classList.some(className => innerEl.classList.contains(className)))
          firstEl.classList.add('first-el-spacing');
      });
    },
  }
]