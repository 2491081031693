/* eslint-disable @typescript-eslint/ban-types */
/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 * @author	BINARY one GmbH
 * @license Unlicensed
 */

import { ENV } from '@libTs/env';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { createVueApp } from '@libTs/vue-base';
import { BASEURL, SITEURL } from './cms.globals';

// Component Imports
import { VueAgile } from 'vue-agile';
import Akkordeon from './components/Akkordeon.vue';
import BannerNewsletter from './components/BannerNewsletter.vue';
import IntroSlides from './components/IntroSlides.vue';
import SlideShow from './components/SlideShow.vue';
import TestimonialSlider from './components/TestimonialSlider.vue';
import WebkitGallery from './components/WebkitGallery.vue';
import WebkitGrid from './components/WebkitGrid.vue';
import WebkitTextGrid from './components/WebkitTextGrid.vue';

// Directive Imports
import { overlay } from './directives/v-overlay.directive';
import { scroll } from './directives/v-scroll.directive';
import { video } from './directives/v-video.directive';
import { spacing } from './directives/v-spacing.directive';



// Plugin Imports
import './plugins/lightbox';
import { Slider } from './slider';
// other Imports
// import './animations';


function onDocumentReady() {
  Slider.initSliders();

  if ($('.slick-dots').children().length == 1) {
    $('.slick-dots').hide();
  }
}
$(onDocumentReady);

// -----------------------------------------------------------
// DEBUG output

if (ENV.debug) {
  console.group('Environment loaded');
  console.log(ENV);
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

// -----------------------------------------------------------
// Vue app

createVueApp(
  // ----------------------------
  // App config
  // It is similar but not equal to "new Vue( { ... } );"
  {
    el: '#app',
    services: [],
    directives: [
      overlay,
      scroll,
      video,
      spacing
    ],
    components: {
      agile: VueAgile,
      Akkordeon,
      BannerNewsletter,
      IntroSlides,
      SlideShow,
      TestimonialSlider,
      WebkitGallery,
      WebkitGrid,
      WebkitTextGrid,
    },
    modules: [],
  }
);

// Add Axios plugin for AJAX requests
Vue.use(VueAxios, axios);
