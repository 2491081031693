<template>
  <div>
    <div class="webkit-textGrid-background">
      <div class="webkit-textGrid-container">
        <div class="webkit-textGrid-container-cell">
          <div class="webkit-textGrid-parent">
            <a
              class="webkit-textGrid-item"
              v-bind:key="index"
              v-for="(block, index) in displayedBlocks"
              v-bind:href="block.url || false"
              :target="block.target"
            >
              <div class="webkit-textGrid-item-grid">
                <div class="webkit-textGrid-cell-background"></div>
                <div
                  class="webkit-textGrid-heading"
                  v-html="block.heading"
                ></div>
                <div class="webkit-textGrid-text" v-html="block.text"></div>
                <div class="webkit-textGrid-button-cell">
                  <div class="webkit-textGrid-button" v-if="block.url">
                    <i class="webkit-btn__small-2 webkit-arrow-right"></i>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="webkit-textGrid-load-more-button-container"
      v-if="blocks.length > numberOfItemsToDisplay"
      v-on:click="showMore"
    >
      <div v-if="loadmorebutton" class="webkit-btn__big">
        {{ loadmorebutton }}
      </div>
      <i v-else class="webkit-btn__small webkit-plus">
        {{ loadmorebutton }}
      </i>
    </div>
  </div>
</template>

<script>
import * as textGridConfig from "theme/modules/textGrid/theme.textGrid.config";
import Vue, { VNode } from 'vue';

export default Vue.extend({
  name: "webkit-text-grid",
  props: {
    blocks: Array,
    loadmorebutton: String,
  },
  data() {
    return {
      numberOfItemsToDisplay: textGridConfig.loadMore.numberOfItemsToShow,
      increment: textGridConfig.loadMore.numberOfItemsIncrement,
      displayedBlocks: [],
    };
  },
  methods: {
    showMore: function () {
      this.numberOfItemsToDisplay += this.increment;
      this.numberOfItemsToDisplay = Math.min(
        this.numberOfItemsToDisplay,
        this.blocks.length
      );
      this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
    },
  },
  created() {
    this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
  },
});
</script>
